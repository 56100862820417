import React from 'react';
import { renderDynamicComponents } from '../../cms-modules';
import RelatedContent from '../../cms-modules/relatedContent';
import ArticleHeader from './components/articleHeader';
import * as styles from './styles.module.scss';

const ArticleDetail = ({ data }) => {
  const { articleContentElements, relatedContent, node_locale } = data;
  const isMagazine = true;
  const renderer = renderDynamicComponents;

  return (
    <>
      <div className={styles.articleWrapper}>
        <ArticleHeader
          articleCategories={data.articleCategories}
          createdAt={data.date || new Date()}
          readingTime={data.readingTime}
          headline={data.headline}
          subline={data.subline}
          images={data.images}
          slug={data.slug}
        />
        {articleContentElements &&
          renderer(articleContentElements, node_locale, isMagazine)}
      </div>
      {relatedContent && <RelatedContent {...relatedContent} />}
    </>
  );
};

export default ArticleDetail;
